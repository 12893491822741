import { useSelector } from "react-redux";
import { groupDocuments } from "../Documents";
import { AppState } from "../../../../../types/state/AppState";
import { DealsState } from "../../../../../types/state/DealsState";
import { useState } from "react";
import { Formik, FormikErrors } from "formik";
import { amrPipelineDetailedActions } from "../../../../../actions";
import { useAppDispatch } from "../../../../../effects/useAppDispatch";
import { AmrDocument } from "../../../../../types/amr-pipeline/models/AmrDocument";
import { dealsActions } from "../../../../../actions/deals.actions";
import { DealsSaveHeader } from "../../../DealsSaveHeader";
import { RouteLeavingGuard } from "../../../../routing";
import { useHistory } from "react-router";
import { DealsTabType } from "../../../../../types/deals/DealsTabType";
import { DocumentType } from "../../../../../types/amr-pipeline/enums/DocumentType";
import { routes } from "../../../../../constants";
import { EditDocumentsSection } from "./EditDocumentsSection";
import { has } from "lodash";
import { AmrFile } from "../../../../../types/amr-pipeline/models/AmrFile";
import { Preloader } from "../../../../common";

export interface DocumentsForm {
    dealDocuments: AmrDocument[];
    distributionReports: AmrDocument[];
    monthlyReports: AmrDocument[];
    reportsExcelFiles: AmrDocument[];
    otherDocuments: AmrDocument[];
}

export const EditDocuments = () => {
    const dispatch = useAppDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const { selectedDeal, isUpdating } = useSelector<AppState, DealsState>(
        (state) => state.deals
    );
    const history = useHistory();

    if (isUpdating) {
        return <Preloader inProgress />
    }

    if (!selectedDeal) {
        return null;
    }

    const { referenceName, legalName, ticker } = selectedDeal;

    const handleSubmit = (values: DocumentsForm) => {
        const documentsList = [
            ...values.dealDocuments,
            ...values.distributionReports,
            ...values.monthlyReports,
            ...values.reportsExcelFiles,
            ...values.otherDocuments,
        ];

        dispatch(
            dealsActions.updateDealDocuments(referenceName, documentsList)
        );
    };

    const handleUpload = (
        name: string,
        documentType: DocumentType,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => Promise<void | FormikErrors<DocumentsForm>>,
        files: AmrFile[],
    ) => {
        setFieldValue(
            name,
            files.map(f => ({
                ...f,
                isPublic: has(f, 'isPublic') ? f.isPublic : true,
                documentType: f.documentType || documentType,
            })),
        );
    };

    return (
        <Formik
            initialValues={groupDocuments(selectedDeal.documents)}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, dirty, isSubmitting, submitForm }) => {
                return (
                    <div className="tabs-content container-flex ">
                        <DealsSaveHeader
                            title="Edit Documents"
                            subTitle={legalName}
                            hasChanges={dirty}
                            isUpdating={isUploading}
                            onSaveClick={submitForm}
                            onCancelClick={() =>
                                history.push(
                                    routes.dealsUrl(
                                        referenceName,
                                        DealsTabType.Documents
                                    )
                                )
                            }
                        />
                        <div className="tabs-data tabs-data-padding tabs-data-documents-edit">
                            <RouteLeavingGuard
                                navigate={(pathname: string) =>
                                    history.push(pathname)
                                }
                                shouldBlockNavigation={() =>
                                    !!dirty && !isSubmitting
                                }
                            />
                            <EditDocumentsSection
                                documentKey="dealDocuments"
                                title="Deal Documents"
                                dealReferenceName={referenceName}
                                items={values.dealDocuments}
                                withTypeColumn
                                setUploadProgress={setIsUploading}
                                onDownloadAll={() =>
                                    dispatch(
                                        amrPipelineDetailedActions.downloadDealRegularDocuments(
                                            referenceName,
                                            ticker
                                        )
                                    )
                                }
                                onUpload={(files) => handleUpload("dealDocuments", DocumentType.otherDealDocument, setFieldValue, files)}

                            />
                            <EditDocumentsSection
                                documentKey="distributionReports"
                                title="Distribution Reports"
                                dealReferenceName={referenceName}
                                items={values.distributionReports}
                                setUploadProgress={setIsUploading}
                                onDownloadAll={() =>
                                    dispatch(
                                        amrPipelineDetailedActions.downloadDealDocuments(
                                            referenceName,
                                            ticker,
                                            DocumentType.distributionReport
                                        )
                                    )
                                }
                                onUpload={(files) => handleUpload("distributionReports", DocumentType.distributionReport, setFieldValue, files)}
                            />
                            <EditDocumentsSection
                                documentKey="monthlyReports"
                                title="Monthly Reports"
                                dealReferenceName={referenceName}
                                items={values.monthlyReports}
                                setUploadProgress={setIsUploading}
                                onDownloadAll={() =>
                                    dispatch(
                                        amrPipelineDetailedActions.downloadDealDocuments(
                                            referenceName,
                                            ticker,
                                            DocumentType.monthlyReport
                                        )
                                    )
                                }
                                onUpload={(files) => handleUpload("monthlyReports", DocumentType.monthlyReport, setFieldValue, files)}
                            />
                            <EditDocumentsSection
                                documentKey="reportsExcelFiles"
                                title="Reports Excel files"
                                dealReferenceName={referenceName}
                                items={values.reportsExcelFiles}
                                setUploadProgress={setIsUploading}
                                onDownloadAll={() =>
                                    dispatch(
                                        amrPipelineDetailedActions.downloadDealDocuments(
                                            referenceName,
                                            ticker,
                                            DocumentType.reportsExcelFiles
                                        )
                                    )
                                }
                                onUpload={(files) => handleUpload("reportsExcelFiles", DocumentType.reportsExcelFiles, setFieldValue, files)}
                            />
                            <EditDocumentsSection
                                documentKey="otherDocuments"
                                title="Other Documents"
                                dealReferenceName={referenceName}
                                items={values.otherDocuments}
                                setUploadProgress={setIsUploading}
                                onDownloadAll={() =>
                                    dispatch(
                                        amrPipelineDetailedActions.downloadDealDocuments(
                                            referenceName,
                                            ticker,
                                            DocumentType.otherDocument
                                        )
                                    )
                                }
                                onUpload={(files) => handleUpload("otherDocuments", DocumentType.otherDocument, setFieldValue, files)}
                            />
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

